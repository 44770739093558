import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import { Color, KanitFont, YearDifference } from '../../constants';
import { StylesDictionary, usePrevious } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services';
import { QRCode } from 'react-qr-svg';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { cancelAppointment, getAppointment, getLatestAppointment, getListAppointment, printAppointment } from '../../services/appointment/actions';
import { Result, Spin } from 'antd';
import { PrimaryButton, ResultsButton } from '../../components/button';
import { message, Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
interface Props {
    title?: string;
}

interface Params {
    appointmentId: string;
}

const ShowAppointmentScreen: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const appointment = useSelector((state: RootState) => state.appointment.appointment);
    const dateTime = moment(appointment.when).add(YearDifference, 'year').format('D MMMM YYYY,H:mm').split(',');
    const { state } = useLocation();
    const param: any = state;
    const { appointmentId } = useParams<Params>();
    const history = useHistory();
    const isGettingAppointment = useSelector((state: RootState) => state.appointment.isGettingAppointment);
    const isCancelAppointment = useSelector((state: RootState) => state.appointment.isCancelAppointment);
    const isPrinting = useSelector((state: RootState) => state.appointment.isPrintAppointment);
    const errorMessage = useSelector((state: RootState) => state.appointment.error);
    const error = useSelector((state: RootState) => state.appointment.errorMessage);
    const prevIsGetAppointment = usePrevious(isGettingAppointment);
    const prevIsCancelAppointment = usePrevious(isCancelAppointment);
    const prevIsPrintAppointment = usePrevious(isPrinting);
    const [isCallSuccess, setIsCallSuccess] = useState(false);
    const printUrl = useSelector((state: RootState) => state.appointment.appointment.print_url);
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const listAppointment = useSelector((state: RootState) => state.appointment.listAppointment);
    
    useEffect(()=>{
        dispatch(getListAppointment());
    },[])

    useEffect(() => {
        if (!isGettingAppointment && prevIsGetAppointment) {
            setIsCallSuccess(true);
        } else {
            setIsCallSuccess(false);
        }
    }, [isGettingAppointment]);

    history.goForward(); 

    useEffect(() => {
        if (!isPrinting && prevIsPrintAppointment) {
            window.open(`${printUrl}`);
        } else {
            <Result
                status={statusMessage}
                title={resultTitle}
            />
        }
    }, [isPrinting]);
    useEffect(() => {
        if (!appointmentId) {
            dispatch(getLatestAppointment());
        } else {
            dispatch(getAppointment(appointmentId));
        }
    }, []);

    const footer = () => {
        return (
            <div style={styles.buttonBox}>
                <PrimaryButton title='ย้อนกลับ' style={styles.button} onClick={() => !appointmentId ?  history.push('/') : history.goBack() } />
            </div>
        )
    }

    const goShowAllAppointment = () => {
        history.push('confirm_appointment/show_all_appointment')
    }

    const openPrintAppoinment = () => {
        if (printUrl === null) {
            dispatch(printAppointment(appointment.id));
        } else {
            window.open(printUrl);
        }
    }

    useEffect(() => {
        if (!isCancelAppointment && prevIsCancelAppointment) {
            if(error.length > 1){
                message.error('เกิดข้อผิดพลาดในการยกเลิก');
                dispatch(getLatestAppointment());
            }else if(error.length < 1){
                message.success('การนัดหมายถูกยกเลิกเเล้ว');
                history.push('/')
            }
        } else {
            return
        }
    }, [isCancelAppointment, isCancelAppointment]);

    const pressOk = () => {
        setConfirmLoading(true);
        setTimeout(()=>{
            dispatch(cancelAppointment(appointment.id));
            setConfirmLoading(false);
            setVisible(false);
            setIsCancel(true)
        },2000)
    }
    
    const pressCancel = () => {
        if (!isCancel) {
            setVisible(false);
        }
    };

    const showModal = () => {
        setVisible(true);
    };

    const isNoAppointment = isCallSuccess && !appointment?.url;
    const isShowError = isNoAppointment || !!errorMessage;
    const title = param?.title ?? props.title;
    const statusMessage = errorMessage ? 'warning' : 'info';
    const resultTitle = errorMessage ? errorMessage : 'ไม่มีข้อมูลการนัดหมายในขณะนี้';

    return (
        <Spin spinning={isGettingAppointment || isPrinting || isCancelAppointment }>
            <VirusScreenLayout title={title} hasBackground={!isShowError} footer={footer()}>
                {isCallSuccess &&
                    (
                        <div style={styles.container}>
                            {isShowError ?
                                <>
                                    <Result
                                        status={statusMessage}
                                        title={resultTitle}
                                    />
                                    {!!listAppointment.length ? <Button onClick={goShowAllAppointment} value="large" style={{ height: 43 }}>ดูการนัดหมายทั้งหมด</Button> : null}
                                </>
                                :
                                (<>
                                    <div style={styles.containArea}>
                                        <h3 style={{ ...styles.text, ...styles.marginVertical }}>{"คุณจะต้องไปทำการฉีดวัคซีนที่"}</h3>
                                        <h3 style={{ ...styles.text, ...styles.marginVertical, ...styles.textColor }}>{appointment.venue}</h3>
                                        <h3 style={{ ...styles.text, ...styles.marginVertical }}>
                                            {"ใน "}
                                            <span style={{ ...styles.textColor }}>
                                                {`วันที่ ${dateTime[0]} เวลา ${dateTime[1]} น.`}
                                            </span>
                                        </h3>
                                        <h3 style={{ ...styles.text, ...styles.NoMargin }}>{"คุณสามารถเข้ามาดูรายละเอียดการนัดหมายนี้ได้ตลอดเวลาที่"}</h3>
                                        <a style={{ ...styles.link, ...styles.NoMargin }} href={appointment.url} rel='noreferrer' target='_blank' >{appointment.url}</a>
                                        <h3 style={{ ...styles.text, ...styles.marginVertical }}>หมายเลขการนัด</h3>
                                        <h3 style={{ ...styles.textColor }}>{appointment.appointment_no}</h3>
                                    </div>
                                    <h3 style={styles.remark}>*กรุณานำสำเนาบัตรประชาชนมาด้วยค่ะ*</h3>
                                    <ResultsButton title='พิมพ์นัดหมาย' style={styles.button} onClick={() => openPrintAppoinment()} />
                                    <h3 style={{ ...styles.text, ...styles.NoMargin }}>{"หรือสแกนที่ QR Code ด้านล่าง"}</h3>
                                    <div style={styles.styleQrcode}>
                                        <QRCode
                                            bgColor={Color.white}
                                            fgColor={Color.black}
                                            style={{ width: 150 }}
                                            value={appointment.url}
                                        />
                                    </div>
                                    <Modal
                                        visible={visible}
                                        onOk={pressOk}
                                        confirmLoading={confirmLoading}
                                        onCancel={pressCancel}
                                        style= {{marginTop: 190 }}
                                        okText="ใช่"
                                        cancelText="ไม่"
                                        closable={false}
                                    >
                                        <ExclamationCircleOutlined style={{ width: 40, color: 'deeppink' }} />
                                        <h4 style={styles.textCencel}> ท่านต้องการยกเลิกการนัดหมายใช่หรือไม่ </h4>
                                    </Modal>

                                    {appointment.status === 'CF' && <>
                                        <Button danger onClick={showModal} style={{ marginBottom: 10, width: 155, height: 43 }}> ยกเลิกการนัดหมาย </Button>
                                        {listAppointment.length > 1 ? <Button onClick={goShowAllAppointment} value="large" style={{ height: 43 }}>ดูการนัดหมายทั้งหมด</Button> : null}
                                    </>}
                                </>)}
                        </div>
                    )}
            </VirusScreenLayout>
        </Spin>
    );
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        alignItems: 'center',
    },
    containArea: {
        flex: 1,
        flexGrow: 1,
        width: '100',
        textAlign: 'center',
    },
    text: {
        fontFamily: KanitFont,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: Color.eclipse,
    },
    link: {
        fontFamily: KanitFont,
        fontSize: 16,
        textAlign: 'center'
    },
    textColor: {
        color: Color.chetwodeBlue,
    },
    marginVertical: {
        marginTop: 16,
        marginBottom: 16,
    },
    NoMargin: {
        margin: 0,
    },
    styleQrcode: {
        backgroundColor: Color.white,
        alignSelf: 'center',
        padding: 16,
        zIndex: 1,
        marginTop: 16,
        marginBottom: 16,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Color.eclipse,
        boxSizing: 'border-box',
        borderRadius: 4,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 16,
    },
    button: {
        marginTop: 8,
        height: 40,
        width: '100%',
    },
    textCencel: {
        fontFamily: KanitFont,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 30
    },
    remark: {
        fontFamily: KanitFont,
        color: 'red',
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    }
}

export default ShowAppointmentScreen;
