import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import SendingOtpScreen from '../scenes/auth';
import LoginScreen from '../scenes/auth/login';
import LoginWithBasicInfo from '../scenes/login-with-basic/login-with-basic';
import LoginWithCitizen from '../scenes/login-with-citizen/login-with-citizen';
import LoginPhoneNumberScreen from '../scenes/login-with-phone-number';
import { PageNotFound } from '../scenes/page-not-found/page-not-found';
import { RootState } from '../services';
import { loadFeatureFlag } from '../services/feature_flag/actions';


const AuthNavigator: React.FC = () => {
  const { state } = useLocation();
  const [titles, setTitles] = useState('')
  const [targetPath, setTargetPath] = useState('')
  const [text, setText] = useState('')
  const [textAdvice, setTextAdvice] = useState('')
  const fromState: any = state;
  const compare = ['/result', '/login_with_birthday', '/appointment','/login_phone_number'];
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const authType = useSelector((state: RootState) => state.featureFlag.authType);
  
  useEffect(()=>{
    dispatch(loadFeatureFlag());
  },[authType])

  useEffect(() => {
    if (fromState?.from.includes(compare[0])) {
      setTitles('ดูผลการตรวจ')
      setTargetPath(fromState.from)
      setText("กรุณากรอกเบอร์โทรศัพท์มือถือเพื่อดูผลการตรวจ ระบบจะส่งรหัส OTP ไปยังโทรศัพท์ของคุณ เพื่อยืนยันตัวตน")
    } else if (fromState?.from === '/register') {
      setTitles('ลงทะเบียน')
      setTargetPath(fromState.from)
      setText("กรุณากรอกเบอร์โทรศัพท์มือถือเพื่อลงทะเบียน ระบบจะส่งรหัส OTP ไปยังโทรศัพท์มือถือของคุณ เพื่อยืนยันตัวตน")
      setTextAdvice("กรุณาใช้เบอร์โทรศัพท์ที่ติดต่อได้ เนื่องจาก เบอร์นี้จะถูกใช้ในการเข้าสู่ระบบเพื่อนัดหมาย เข้าทำการตรวจและแจ้งผลตรวจ")
    } else if (fromState?.from.includes(compare[1])) {
      setTitles('ดูผลการตรวจ')
      setTargetPath(fromState.from)
    } else if (fromState?.from.includes(compare[2])) {
      setTitles('ดูการนัดหมาย')
      setTargetPath(fromState.from)
    } else if (fromState?.from.includes(compare[3])) {
      setTitles('ลงทะเบียน')
      setTargetPath(fromState.from)
      setTextAdvice("กรุณาใช้เบอร์โทรศัพท์ที่ติดต่อได้ เนื่องจาก เบอร์นี้จะถูกใช้ในการเข้าสู่ระบบเพื่อนัดหมาย เข้าทำการตรวจและแจ้งผลตรวจ")
    } else if(!titles)  {
      history.push('/');
    }
  }, [])

  return (
    <Switch>
      <Route path={`${path}/login_phone_number`}>
        <LoginPhoneNumberScreen title={titles} go={targetPath} />
      </Route>
      <Route path={`${path}/login_with_birthday`}>
        <LoginWithBasicInfo title={titles} go={targetPath} />
      </Route>
      <Route path={`${path}/login_appointment`}>
        <LoginWithCitizen title={titles} go={targetPath} />
      </Route>
      <Route exact path={`${path}/get_otp`}>
        <SendingOtpScreen title={titles} go={targetPath} text={text} textAdvice={textAdvice} />
      </Route>
      <Route exact path={`${path}/verify_otp`}>
        <LoginScreen title={titles} go={targetPath} />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default AuthNavigator;
