import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { ShowAppointmentScreen } from '../scenes/appointment';
import { PageNotFound } from '../scenes/page-not-found/page-not-found';
import { RegisterScreen, ConfirmRegisterScreen } from '../scenes/register';
import ResultScreen from '../scenes/result';
import AppointmentNavigator from './appointment-navigator';


const MainNavigator: React.FC = () => {
  const protected_paths = ['/result/my', '/login_with_birthday'];

  return (
    <Switch>
      <Route exact path={protected_paths}>
        <ResultScreen title="ดูผลการตรวจ" />
      </Route>
      <Route path="/appointment">
        <AppointmentNavigator />
      </Route>
      <Route exact path="/appointment/my/">
        <ShowAppointmentScreen title="ดูผลการตรวจ" />
      </Route>
      <Route exact path="/result/:resultId">
        <ResultScreen title="ดูผลการตรวจ" />
      </Route>
      <Route exact path="/register">
        <RegisterScreen title="ลงทะเบียน" />
      </Route>
      <Route exact path="/confirm_register">
        <ConfirmRegisterScreen title="ลงทะเบียน" />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default MainNavigator;
