import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout';
import { PrimaryButton } from '../../components/button';
import { useHistory } from 'react-router-dom';
import { StylesDictionary, usePrevious } from '../../utils/utils';
import { Alert, Form, Input, Spin } from 'antd';
import NumberFormat from 'react-number-format';
import { KanitFont, Color } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithCitizen, setForm } from '../../services/auth/actions';
import './auth.css';
import { RootState } from '../../services';

interface Props {
  title?: string;
  text?: string;
  textAdvice?: string;
  go?: string;
}

const LoginPhoneNumberScreen: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const getPhone = (e: any) => {
    form.setFieldsValue({ phone_no: e.value });
    form.setFieldsValue({ citizen_id: '1555546545875' });
    form.validateFields(['phone_no']);
  };

  const errorMessage = useSelector((state: RootState) => state.auth.errorMessage);
  const isLoggingIn = useSelector((state: RootState) => state.auth.isLoggingIn)
  const prevIsLoggingIn = usePrevious(isLoggingIn)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoggingIn && prevIsLoggingIn === !isLoggingIn && !errorMessage) {
        setLoading(false);
        if (props.go) {
            history.push(props.go);
        }
    } else if (!isLoggingIn && prevIsLoggingIn === !isLoggingIn && errorMessage) {
        setLoading(true);
    }
}, [isLoggingIn, errorMessage]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    dispatch(setForm(values.phone_no));
    dispatch(loginWithCitizen(values));
      setLoading(true);
  };

  const footer = () => {
    return (
      <div style={styles.buttonBox}>
        <PrimaryButton
          style={styles.button}
          title="ยืนยัน"
          onClick={onSubmit}
          disabled={isError}
        />
      </div>
    );
  };
  const isLoading = !errorMessage ? loading: !loading
  return (
    <Spin spinning={isLoading}>
      <VirusScreenLayout title={props.title} footer={footer()}>
        <div style={styles.container}>
          <h3 style={styles.title}>กรุณากรอกเบอร์โทรศัพท์มือถือ</h3>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            style={styles.textArea}
            className="auth-page"
            onFieldsChange={(fieldsValue) =>
              setIsError(
                !!fieldsValue.filter(({ errors }) => errors!.length).length
              )
            }
          >
            <Form.Item
              name="phone_no"
              rules={[
                { required: true, message: 'กรุณากรอกเบอร์โทรศัพท์มือถือ' },
                { min: 10, message: 'เบอร์โทรศัพท์ 10 ตัวเท่านั้น' },
                { max: 10, message: 'เบอร์โทรศัพท์ 10 ตัวเท่านั้น' },
                {
                  pattern: /^[0][1-9]/,
                  message: 'กรอกเบอร์โทรศัพท์ไม่ถูกต้อง',
                },
              ]}
            >
              <Input hidden />
              <NumberFormat
                onValueChange={getPhone}
                className="ant-input"
                style={styles.input}
                placeholder="XXX-XXX-XXXX"
                format="###-###-####"
              />
            </Form.Item>
          </Form>
          <p> {props.text} </p>
          {props.textAdvice && <p style={styles.h5}> {props.textAdvice} </p>}
        </div>
        <div style={styles.styleError}>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              style={styles.errorMessage}
              showIcon
            />
          )}
        </div>
      </VirusScreenLayout>
    </Spin>
  );
};

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    textAlign: 'center',
  },
  bg: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  textArea: {
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
  },
  button: {
    height: 40,
    width: '100%',
  },
  input: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.pictonBlue,
    borderRadius: 4,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    color: Color.pictonBlue,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: Color.white,
  },
  title: {
    fontFamily: KanitFont,
    fontSize: 14,
  },
  h5: {
    fontSize: 16,
    color: Color.chetwodeBlue,
    textAlign: 'center',
  },
  styleError: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'center',
  },
};

export default LoginPhoneNumberScreen;
