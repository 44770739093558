export const GET_FEATURE_FLAG_PENDING = "GET_FEATURE_FLAG_PENDING";
export const GET_FEATURE_FLAG_SUCCESS = "GET_FEATURE_FLAG_SUCCESS";

export interface AppError {
  message: string;
}

export enum AuthType {
  otp = 'otp',
  citizen = 'citizen',
  passport_id = 'passport_id'
}

export interface FeatureFlagState {
  isLoading: boolean,
  riskAssessmentEnabled: boolean;
  myTestResultEnabled: boolean;
  authType: AuthType;
}

export interface GetFeatureFlagPendingAction {
  type: typeof GET_FEATURE_FLAG_PENDING;
}

export interface GetFeatureFlagSuccessAction {
  type: typeof GET_FEATURE_FLAG_SUCCESS;
  riskAssessmentEnabled: boolean;
  myTestResultEnabled: boolean;
  authType: AuthType;
}

export type FeatureFlagActionType =
  | GetFeatureFlagPendingAction
  | GetFeatureFlagSuccessAction;
