import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AuthNavigator from './auth-navigator';
import MainNavigator from './main-navigator';
import axios, { AxiosRequestConfig } from 'axios';
import { unauthorizedUrls, url } from '../constants';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../services';
import moment from 'moment';
import 'moment/locale/th'
import QuestionnaireNavigator from './questionnaire-navigator';
import HomeScreen from '../scenes/home';
import { PageNotFound } from '../scenes/page-not-found/page-not-found';
import { getLatestAppointment } from '../services/appointment/actions';
import { loadFeatureFlag } from '../services/feature_flag/actions';
import { AuthType } from '../services/feature_flag/types';

moment.locale('th')

const AppNavigator: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token)
  const protected_paths = ['/result', '/register', '/appointment', '/login_with_birthday', '/login_appointment'];
  const dispatch = useDispatch();
  const riskAssessmentEnabled = useSelector((state: RootState) => state.featureFlag.riskAssessmentEnabled);
  const authType = useSelector((state: RootState) => state.featureFlag.authType);
  const [isAuthType, setIsAuthType] = useState(false)

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) =>
      authorizeReq(token)(config)
    );
    dispatch(getLatestAppointment(token));
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [token]);

  useEffect(()=>{
    dispatch(loadFeatureFlag());
    if (authType === AuthType.otp){
      setIsAuthType(true)
    } else if (authType === AuthType.citizen){
      setIsAuthType(false)
    }
  },[authType])

  const checkPathLogin = (location : string) => {
    if ( location === '/appointment/my/' ) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login_appointment',
          state: { from: location }
        }} />
      )  
    } else if (location === '/login_with_birthday') {
      return (
        <Redirect
         to={{
           pathname: '/auth/login_with_birthday',
           state: { from: location }
         }} />
      )
    } else if (isAuthType) {
      return (
        <Redirect
          to={{
            pathname: '/auth/get_otp',
            state: { from: location }
        }} />
      ) 
    } else {
      return <Redirect
          to={{
            pathname: '/auth/login_phone_number',
            state: { from: location }
          }} 
        />
    }      
  }
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomeScreen  />
        </Route>
        <Route path="/auth">
          <AuthNavigator />
        </Route>
        {riskAssessmentEnabled && <Route path="/questionnaires">
          <QuestionnaireNavigator />
        </Route>}
        <Route render={({ location }) =>
          token
            ? <MainNavigator />
            : (protected_paths.some((path) => location.pathname.includes(path))
              ? checkPathLogin(location.pathname)
              : <PageNotFound />)
        } />
      </Switch>
    </Router>
  );
}

export default AppNavigator;

const authorizeReq = (token: string) => (config: AxiosRequestConfig) => {
  let headers: any = {
    'Content-Type': 'application/json',
  };
  let urlApi: string;
  if (config.url) {
    urlApi = config.url.split(url(''))[1];
  }
  if (!unauthorizedUrls.some((api) => api === urlApi)) {
    headers = {
      ...headers,
      Authorization: `Token ${token}`,
    };
  }
  config.headers = { ...config.headers, ...headers };

  return config;
};
